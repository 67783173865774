<template>
    <section class="blockElement">
        <div>
            <div class="rightTradeChart position-relative" style="height: 250px;">
                <div class="bar-chart position-relative">
                    <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                    <div
                        id="dashboardMobileChart"
                        class="dashboardMobileChart"
                        v-show="Object.keys(store.userDashboardDetail).length && store.userDashboardDetail.performanceChart && store.userDashboardDetail.performanceChart.length"
                    ></div>
                    <Nodata v-if="Object.keys(store.userDashboardDetail).length && store.userDashboardDetail.performanceChart && !store.userDashboardDetail.performanceChart.length"></Nodata>
                </div>
            </div>
           <!--  <ul class="d-flex align-items-center justify-content-evenly">
                <li v-for="list in dateDrop" :key="list.key">
                    <a class="f-14 gray selectDay" @click.prevent="dateDropSelected = list;getDashboardData()" :class="[{ 'active': dateDropSelected.value == list.value }]" href="javascript:void(0);">{{ list.value }}</a>
                </li>
            </ul> -->
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import Highcharts from 'highcharts';
    // import * as am5 from "@amcharts/amcharts5";
    // import * as am5xy from "@amcharts/amcharts5/xy";
    // import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    // import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                // dateDrop: [
                //     { key: 7, value: "1W" },
                //     { key: 30, value: "1M" },
                //     { key: 365, value: "1Y" },
                //     { key: 10000, value: "All" },
                // ],
                // dateDropSelected: { key: 10000, value: "All" },
            };
        },
        methods: {
            UserDashboardChart(id, data) {
                let chartOptions = {
                    title: {
                        text:''
                    },
                    chart: {
                        // type: 'area',
                        // spacingLeft: 0,
                        // spacingRight: 0
                        backgroundColor: 'var(--bodycolor)'

                        // --secondary
                        // spacing : [0, -6, 0, -6],
                    },
                    credits:{
                        enabled: false,
                    },
                    yAxis:{

                        title: {
                            format: '{value:,}',
                            text: "",
                            style:{
                                color:"var(--title)",
                            }
                        },
                        crosshair:{
                            color: '#cccccc',
                            dashStyle: 'Solid',
                            snap:true,
                            width:1,
                            zIndex:2
                        },
                        labels:{
                            style: {
                               color:"var(--dark)",
                            }
                        },
                        // visible:false,
                        lineWidth: 0,
                        gridLineColor: "transparent",
                        // lineColor: "transparent"
                    },
                    xAxis: {
                        labels:{
                            format: '{value:%b %d}',
                            dateFormat: 'mm/dd/YYYY',
                            style: {
                                textOverflow: 'none',
                                fontWeight: 'medium',
                                color:"var(--dark)",
                                // fontSize: '20px',
                            }
                        },
                        tickColor: "var(--bodycolor)",
                        crosshair:{
                            color: '#cccccc',
                            dashStyle: 'Solid',
                            snap:true,
                            width:1,
                            // zIndex:2
                        },
                        gridLineColor: "var(--bodycolor)",
                        lineColor: "var(--bodycolor)",
                        // visible:false,
                        // showFirstLabel: false,
                        // showLastLabel: false,
                        type: 'datetime',
                    },
                    plotOptions: {
                        area: {
                            fillColor: {
                                linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0},
                                stops: [
                                    [0, 'var(--bodycolor)'],
                                    [1, 'var(--secondary)']
                                ]
                            },
                            // selected:true,
                            // fillColor: "#fef2eb",
                            // Color: "#fef2eb",
                            // negativeFillColor: "#fef2eb",
                            // softThreshold: false,
                            threshold: null,
                            marker: {
                                enabled: true,
                                radius: 1,
                                enabledThreshold: 10,
                                selected: false,
                            },                            
                        },

                        series: {
                            color: 'var(--secondary)',
                        },

                    },
                    series: [
                        {
                            marker: {
                                enabled: true,
                                radius: 1,
                                enabledThreshold: 10,
                                selected: false,
                            },
                            selected: false,
                            showInLegend: false,
                            name: 'Equity',
                            type: 'area',
                            data: data,
                            lineWidth:1.5,
                            pointInterval: 12 * 3600 * 1000   
                        }
                    ]
                };

                Highcharts.setOptions({
                    lang: {
                        decimalPoint: ".",
                        thousandsSep: ","
                    }
                });
                Highcharts.chart('dashboardMobileChart', chartOptions);
            },
            getDashboardData() {
                if (!this.store.user.access_token) {
                    this.store.$patch({ user: { access_token: this.$route.params.token } });
                }
                let form = {};
                if (this.$route.query.timeframe) {
                    form["timeframe"] = this.$route.query.timeframe
                }else{
                     form["timeframe"] = 30
                }
                if (this.$route.query.targetZuluAccountId) {
                    form["targetZuluAccountId"] = this.$route.query.targetZuluAccountId;
                }
                if (this.$route.query.demo) {
                    form["demo"] = this.$route.query.demo;
                }
                this.store.getUserDashboardData(form, true).then((response) => {
                    if (Object.keys(response).length && response.performanceChart && response.performanceChart.length) {
                        this.UserDashboardChart("dashboardMobileChart", response.performanceChart);
                    }
                });
            },
        },
        created() {
            if (this.$route.params.token) {
                this.getDashboardData();
            }
        },
    };
</script>
<style>
    .dashboardMobileChart {
        height: 250px;
    }
</style>
